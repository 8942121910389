import {
  FETCH_INIT,
  FETCH_SUCCESS,
  FETCH_FAIL,
  CREATE_INIT,
  CREATE_SUCCESS,
  CREATE_FAIL,
  UPDATE_INIT,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "../actions/types";

const initialState = { isLoading: false, user: [] };

export default function userreducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload.user.data,
      };
    case FETCH_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        user: null,
        message: payload.message,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload.user.data,
        message: "Created Successfully",
      };
    case CREATE_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
        user: null,
        message: payload.message,
      };
    case UPDATE_SUCCESS:
      return {};
    // return {
    //   ...state,
    //   isLoading: false,
    //   message: "Updated Successfully",
    // };
    case UPDATE_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_FAIL:
      return {};
    // return {
    //   ...state,
    //   isLoading: false,
    //   message: payload.message,
    // };

    default:
      return state;
  }
}
