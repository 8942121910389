import axios from "axios";

const baseURL = process.env.REACT_APP_baseURL;

const getAll = () => {
    return axios.get(
        `${baseURL}/subscribers`
    );
};

export default {
    getAll,
};
