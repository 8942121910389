/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";

import { Route, Redirect } from "react-router-dom";

class ProtectedRoute extends React.Component {
  getRoute = () => {
    const { path, component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (localStorage.getItem("auth")) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  };

  render() {
    return this.getRoute();
  }
}

export default ProtectedRoute;
