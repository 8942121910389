import React from "react";
import "./alert.css";
import PropTypes from "prop-types";

function Alert(props) {
  const { status, message } = props;
  return (
    <div className="container">
      <div className="alert alert-success alert-dismissible fade show d-flex justify-content-between">
        <span>
          <strong>{status} : </strong> {message}.
        </span>
        <span className="btn close" data-dismiss="alert">
          &times;
        </span>
      </div>
    </div>
  );
}

Alert.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
export default Alert;
