import { combineReducers } from "redux";
import loginreducer from "./auth";
import userreducer from "./user";
import emailreducer from "./email";
import contactreducer from "./contact";
import careerreducer from "./career";
import subscriberreducer from "./subscriber";

export default combineReducers({
  login: loginreducer,
  user: userreducer,
  email: emailreducer,
  contact: contactreducer,
  career: careerreducer,
  subscriber: subscriberreducer,
});
