import {
  SUBSCRIBER_FETCH_SUCCESS,
  FETCH_FAIL
} from "../actions/types";

const initialState = { isLoading: false, subscriber: [] };

export default function userreducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIBER_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriber: payload.subscriber.data,
      };
    case FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        subscriber: null,
        message: payload.message,
      };

    default:
      return state;
  }
} 
