import React from "react";
import "./user-edit.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { create } from "../../store/actions/user";
import store from "../../store/store";

import Layout from "../../commons/layout/layout";
import Spinner from "../../commons/spinner/spinner";
import Alert from "../../commons/alert/alert";

function mapStateToProps(state) {
  return {
    isLoading: state.user.isLoading,
    message: state.user.message,
  };
}

class AddUser extends React.Component {
  title = "Add User";

  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      role: "",
      redirectToReferrer: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  create = async () => {
    const payload = {
      name: this.state.name,
      userName: this.state.username,
      email: this.state.email,
      password: this.state.password,
      role: this.state.role,
    };

    await store.dispatch(create(payload));
  };

  render() {
    const { name, username, email, password, role } = this.state;
    const { message, isLoading } = this.props;
    return (
      <div className="Add-User">
        <div className="alertt">
          <div className="flexAlert">
            <div>
              {message ? <Alert message={message} status="Failure" /> : ""}
            </div>
          </div>
        </div>
        <Layout>
          <div className="heading-container">
            <h2>{this.title}</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-5 d-flex justify-content-around">
                <form className="col-lg-5 ">
                  <input
                    type="text"
                    className="form-control"
                    id="inputName4"
                    placeholder="Name"
                    data-validate="Name is required"
                    name="name"
                    required
                    value={name}
                    onChange={this.handleInputChange}
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="inputName4"
                    name="username"
                    placeholder="Username"
                    data-validate="Username is required"
                    value={username}
                    onChange={this.handleInputChange}
                  />

                  <input
                    type="email"
                    className="form-control"
                    id="inputName4"
                    name="email"
                    placeholder="Email"
                    data-validate="email is required"
                    value={email}
                    onChange={this.handleInputChange}
                  />

                  <input
                    type="password"
                    className="form-control"
                    id="inputName4"
                    name="password"
                    placeholder="Password"
                    data-validate="password is required"
                    value={password}
                    onChange={this.handleInputChange}
                  />

                  <select
                    class="form-control"
                    name="role"
                    placeholder="Role"
                    data-validate="role is required"
                    value={role}
                    onChange={this.handleInputChange}
                  >
                    <option value="Admin">Admin</option>
                    <option value="Incharge">Incharge</option>
                    <option value="Role">Role</option>
                  </select>
                  <div className="col-lg-12 mt-4">
                    <button
                      className="btn button-color"
                      type="button"
                      onClick={() => this.create()}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {isLoading ? <Spinner /> : ""}
        </Layout>
      </div>
    );
  }
}

AddUser.propTypes = {
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default connect(mapStateToProps)(AddUser);
