/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import "./user-add.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { create } from "../../store/actions/user";
import store from "../../store/store";

import Layout from "../../commons/layout/layout";
import Spinner from "../../commons/spinner/spinner";
import Alert from "../../commons/alert/alert";

function mapStateToProps(state) {
  return {
    isLoading: state.user.isLoading,
    message: state.user.message,
  };
}

class AddUser extends React.Component {
  title = "Add User";

  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      role: "",
      redirectToReferrer: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  create = async () => {
    if (
      !this.state.name ||
      !this.state.email ||
      !this.state.username ||
      !this.state.password
    ) {
      alert("Please fill the required fields");
      return;
    }

    if (this.state.role === "null") {
      alert("Please select the role");
      return;
    }

    // Validation starts
    const emailPattern = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const nameLimit = 25;
    const passwordLimit = 15;
    const userNameLimit = 15;

    // validation
    if (this.state.name.trim().length > nameLimit) {
      alert(
        `Validation Failed, Name field should be less than equals to ${nameLimit} characters`
      );
      return;
    }

    if (this.state.username.trim().length > userNameLimit) {
      alert(
        `Validation Failed, Username field should be less than equals to ${userNameLimit} characters`
      );
      return;
    }

    if (emailPattern.test(this.state.email) === false) {
      alert(
        `Validation Failed, Email field should contain a valid email address`
      );
      return;
    }

    if (this.state.password.trim().length > passwordLimit) {
      alert(
        `Validation Failed, Password field should be less than equals to ${passwordLimit} characters`
      );
      return;
    }

    const payload = {
      name: this.state.name,
      userName: this.state.username,
      email: this.state.email,
      password: this.state.password,
      role: this.state.role,
    };
    await store
      .dispatch(create(payload))
      .then(() => {
        alert(`User added successfully`);
        window.location.reload();
      })
      .catch(() => {
        alert(`Error Occurred please try again`);
      });
  };

  render() {
    const { name, username, email, password, role } = this.state;
    const { message, isLoading } = this.props;
    return (
      <div className="Add-User">
        <div className="alertt">
          <div className="flexAlert">
            <div>
              {message ? <Alert message={message} status="Failure" /> : ""}
            </div>
          </div>
        </div>
        <Layout>
          <div className="container">
            <div className="heading-container">
              <h2>{this.title}</h2>
            </div>
            <div className="row">
              <div className="col-lg-12 p-5 d-flex justify-content-around">
                <div className="col-md-12">
                  <form className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName4"
                          placeholder="Name"
                          data-validate="Name is required"
                          name="name"
                          required
                          value={name}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Username*</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName4"
                          name="username"
                          placeholder="Username"
                          data-validate="Username is required"
                          value={username}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label>Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputName4"
                          name="email"
                          placeholder="Email"
                          data-validate="Email is required"
                          value={email}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Password*</label>
                        <input
                          type="password"
                          className="form-control"
                          id="inputName4"
                          name="password"
                          placeholder="Password"
                          data-validate="Passwored is required"
                          value={password}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>Select Role*</label>
                        <select
                          className="form-control"
                          name="role"
                          placeholder="role"
                          data-validate="Role is required"
                          value={role}
                          onChange={this.handleInputChange}
                        >
                          <option value="null">Select Role</option>
                          <option value="Admin">Admin</option>
                          <option value="Incharge">Incharge</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <br />
                      <button
                        className="btn button-color add-user-btn"
                        type="button"
                        onClick={() => this.create()}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? <Spinner /> : ""}
        </Layout>
      </div>
    );
  }
}

AddUser.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(AddUser);
