import "./navigation.css";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../store/store";
import { logout } from "../../store/actions/auth";
import img from "../../assets/img_avatar.png";

function LogOut() {
  store.dispatch(logout());
}

function Navigation() {
  const currentUser = useSelector((state) => state.login.user);
  const navLinks = [
    {
      label: "Users",
      submenu: [
        { url: "/add-user", name: "Add" },
        { url: "/view-user", name: "View" },
      ],
    },
    {
      label: "Subscribers",
      submenu: [{ url: "/view-subscriber", name: "View" }],
    },
    {
      label: "Contact Us",
      submenu: [{ url: "/view-contact-list", name: "View Queries" }],
    },
    {
      label: "Careers",
      submenu: [{ url: "/view-career-list", name: "View Jobs" }],
    },
  ];

  return (
    <>
      <div className="navigation">
        {currentUser ? (
          <>
            <div className="logged-in-user">
              <div className="img-cont">
                <img src={img} alt="img-person" />
              </div>
              <div>
                <hr className="logged-in-user-avatar-line" />
              </div>
              <div className="log-in">
                <p className="logged-in-user-name">
                  {" "}
                  {localStorage.getItem("name")}
                </p>
                <p className="logged-in-user-email">
                  {" "}
                  {localStorage.getItem("email")}
                </p>
                <p className="logged-in-user-role">
                  {" "}
                  {localStorage.getItem("role")}
                </p>
                <hr className="logged-in-user-line" />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <ul className="nav flex-column flex-nowrap overflow-hidden list-style">
          {navLinks.map((item, index) => {
            return (
              <div key={index}>
                {currentUser ? (
                  <li className="nav-item-parent">
                    <a
                      className="nav-link-parent collapsed"
                      href={`#submenu${index}`}
                      data-toggle="collapse"
                      data-target={`#submenu${index}`}
                    >
                      <i className="fa fa-table" />{" "}
                      <span className="d-none d-sm-inline">{item.label}</span>
                    </a>
                    <div
                      className="collapse"
                      id={`submenu${index}`}
                      aria-expanded="false"
                    >
                      <ul className="flex-column pl-2 nav">
                        {item.submenu.map((nav, item) => (
                          <li className="nav-item" key={item}>
                            <a className="nav-link py-0 mb-2" href={nav.url}>
                              <span>{nav.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </div>
            );
          })}

          {currentUser ? (
            <li className="nav-item-parent">
              <a
                className="nav-link-parent text-truncate"
                href="#!"
                onClick={LogOut}
              >
                <i className="fa fa-home" />{" "}
                <span className="d-none d-sm-inline">Logout</span>
              </a>
            </li>
          ) : (
            <>
              <li className="nav-item-parent">
                <Link to="/login" className="nav-link-parent">
                  <i className="fa fa-share" />
                  Log In
                  <span className="sr-only">(current)</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
}

export default Navigation;
