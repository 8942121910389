import { LOGIN_SUCCESS, LOGIN_INIT, LOGIN_FAIL, LOGOUT } from "./types";

import AuthService from "../../services/auth.service";
import jwt_decode from "jwt-decode";

export const login = (username, password) => (dispatch) => {
  dispatch({
    type: LOGIN_INIT,
  });
  return AuthService.login(username, password).then(
    (data) => {
      const decoded = jwt_decode(data.data.accessToken);
      localStorage.setItem("auth", true);
      localStorage.setItem("accessToken", data.data.accessToken);
      localStorage.setItem("email", decoded.email);
      localStorage.setItem("name", data.data.name);
      localStorage.setItem("role", data.data.role);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
        payload: { message },
      });

      dispatch({
        type: "SET_MESSAGE",
        payload: message,
      });

      return Promise.reject(error);
    }
  );
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("email");
  localStorage.removeItem("name");
  localStorage.removeItem("role");
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
