/* eslint-disable no-console */
import React from "react";
import dateFormat from "dateformat";
import "./subscriber-view.css";
import { connect } from "react-redux";
import { getAll } from "../../store/actions/subscriber";
import store from "../../store/store";

import Layout from "../../commons/layout/layout";
import Spinner from "../../commons/spinner/spinner";
import Alert from "../../commons/alert/alert";

function mapStateToProps(state) {
  return {
    isLoading: state.subscriber.isLoading,
    message: state.subscriber.message,
    subscriber: state.subscriber.subscriber,
  };
}

class SubscriberView extends React.Component {
  title = "Subscribers";

  constructor() {
    super();
    this.state = {
      email: "",
      createdAt: "",
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    this.getAllSubscriber();
  }

  getAllSubscriber = () => {
    store.dispatch(getAll()).then((data) => {});
  };

  render() {
    const { message, isLoading, subscriber } = this.props;
    return (
      <div className="Add-User">
        <div className="alertt">
          <div className="flexAlert">
            <div>
              {message ? <Alert message={message} status="Failure" /> : ""}
            </div>
          </div>
        </div>
        <Layout>
          <div className="container">
            <div className="heading-container">
              <h2>{this.title}</h2>
            </div>
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Email</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriber ? (
                    subscriber.map((item, x) => (
                      <tr key={x}>
                        <td>{x + 1}</td>
                        <td>{item.email}</td>
                        <td>{dateFormat(item.createdAt, "dd-mmm-yyyy")}</td>
                      </tr>
                    ))
                  ) : (
                    <h5 style={{ textAlign: "center", marginTop: "100px" }}>
                      Currently there no Subscribers exist.
                    </h5>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {isLoading ? <Spinner /> : ""}
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SubscriberView);
