/* eslint-disable no-alert */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import "./login.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { login } from "../../store/actions/auth";
import store from "../../store/store";

import Layout from "../../commons/layout/layout";
import Spinner from "../../commons/spinner/spinner";
import Alert from "../../commons/alert/alert";

function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    data: state.login.data,
    message: state.login.message,
    user: state.login.user,
  };
}

class Login extends React.Component {
  title = "Login Form";

  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      redirectToReferrer: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  login = () => {
    const { username, password } = this.state;
    store
      .dispatch(login(username, password))
      .then(() => {
        this.setState({ redirectToReferrer: true });
      })
      .catch(() => {
        alert(`Email and Password is not correct`);
      });
  };

  changeLastName = () => {};

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { username, password, redirectToReferrer } = this.state;
    const { message, isLoading } = this.props;
    if (redirectToReferrer === true) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="Login">
        <div className="alertt">
          <div className="flexAlert">
            <div>
              {message ? <Alert message={message} status="Failure" /> : ""}
            </div>
          </div>
        </div>
        <Layout>
          <div className="container bg-light">
            <div className="d-flex flex-column justify-content-center align-content-center">
              <h2>{this.title}</h2>
              <br />

              <label htmlFor="uname">
                <b>Email</b>
              </label>
              <input
                type="text"
                placeholder="Enter Email"
                name="username"
                required
                value={username}
                onChange={this.handleInputChange}
              />

              <label htmlFor="psw">
                <b>Password</b>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                required
                value={password}
                onChange={this.handleInputChange}
              />

              <button type="submit" onClick={this.login}>
                Login
              </button>
            </div>
          </div>
          {isLoading ? <Spinner /> : ""}
        </Layout>
      </div>
    );
  }
}

Login.propTypes = {
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Login);
