/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "./modal.css";

// eslint-disable-next-line react/prefer-stateless-function
class CareeerDetailsModal extends Component {
  render() {
    const { handleClose, show, data } = this.props;
    const showHideClassName = show
      ? "overlay2 modal display-block"
      : "overlay2 modal display-none";
    return (
      <div>
        <div className={showHideClassName}>
          <div className="ModalContainer">
            <div className="ModalDic">
              <img className="imgStyle" alt="error" />
              <h1 className="headingStyle">Job Request Details</h1>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <label>
                      <b>Name</b>
                    </label>
                    <br />
                    <p>{data.name}</p>
                  </div>
                  <div className="col-md-6">
                    <label>
                      <b>Email</b>
                    </label>
                    <br />
                    <p>{data.email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>
                      <b>Contact</b>
                    </label>
                    <br />
                    <p>{data.contactNo}</p>
                  </div>
                  <div className="col-md-6">
                    <label>
                      <b>Date</b>
                    </label>
                    <br />
                    <p>{data.date}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>
                      <b>Job Title</b>
                    </label>
                    <br />
                    <p>{data.jobTitle}</p>
                  </div>
                  <div className="col-md-6">
                    <label>
                      <b>CV / Resume </b>
                    </label>
                    <br />
                    <p>
                      <a href={data.attachment}>View</a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="buttonStyle"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CareeerDetailsModal;
