import axios from "axios";

const baseURL = process.env.REACT_APP_baseURL;

const getAll = (pageNum, pageSize) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.get(
    `${baseURL}/career-forms?pageSize=${pageSize}&pageNum=${pageNum}`,
    {
      headers,
    }
  );
};

const createJob = (payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.post(`${baseURL}/career-form`, payload, {
    headers,
  });
};

export default {
  getAll,
  createJob,
};
