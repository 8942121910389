import React from "react";
import "./spinner.css";

function Spinner(props) {
  const { overlayPosition, loaderStyle } = props;
  return (
    <div>
      <div className={`overlay ${overlayPosition}`}>
        <div className="inner">
          <div className={`loader ${loaderStyle}`}>Loading...</div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
