import React from "react";
import dateFormat from "dateformat";
import "./contact-list.css";
import { connect } from "react-redux";

import { getAll } from "../../store/actions/contact";
import store from "../../store/store";

import Layout from "../../commons/layout/layout";
import Spinner from "../../commons/spinner/spinner";
import Alert from "../../commons/alert/alert";
import Modal from "../../commons/modal/contactUsDetailModal";

function mapStateToProps(state) {
  return {
    isLoading: state.contact.isLoading,
    message: state.contact.message,
    user: state.contact.user,
  };
}

class ContactList extends React.Component {
  title = "Queries";

  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      role: "",
      redirectToReferrer: false,
      show: false,
      detail: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.getAllUser();
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  getAllUser = () => {
    store.dispatch(getAll(1, 1000)).then(() => {});
  };

  showModal = (item) => {
    const ele = {
      name: item.fullName,
      email: item.email,
      mobileNo: item.mobileNo,
      query: item.query,
      date: dateFormat(item.createdAt, "dd-mmm-yyyy"),
    };

    this.setState({ show: true, detail: ele });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    const { message, isLoading, user } = this.props;
    return (
      <div className="Add-User">
        <div className="alertt">
          <div className="flexAlert">
            <div>
              {message ? <Alert message={message} status="Failure" /> : ""}
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          heading="Contact Us Detail"
          handleClose={this.hideModal}
          data={this.state.detail}
          status="Failure"
        />
        <Layout>
          <div className="container">
            <div className="heading-container">
              <h2>{this.title}</h2>
            </div>
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {user ? (
                    user.map((item, x) => (
                      <tr key={x}>
                        <td>{x + 1}</td>
                        <td>{item.fullName}</td>
                        <td>{item.mobileNo}</td>
                        <td>{dateFormat(item.createdAt, "dd-mmm-yyyy")}</td>
                        <td>
                          <button
                            className="btn button-color"
                            type="button"
                            onClick={() => this.showModal(item)}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h5 style={{ textAlign: "center", marginTop: "100px" }}>
                      Currently there are no Contact US Queries.
                    </h5>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {isLoading ? <Spinner /> : ""}
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ContactList);
