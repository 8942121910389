import axios from "axios";

const baseURL = process.env.REACT_APP_baseURL;

const getAll = (pageNum, pageSize) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.get(`${baseURL}/users?pageSize=${pageSize}&pageNum=${pageNum}`, {
    headers,
  });
};
const createUser = (payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.post(`${baseURL}/user`, payload, {
    headers,
  });
};

const updateUser = (payload, id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.post(`${baseURL}/user/${id}`, payload, {
    headers,
  });
};

export default {
  getAll,
  createUser,
  updateUser,
};
