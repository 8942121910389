/* eslint-disable import/prefer-default-export */
import { FETCH_INIT, FETCH_SUCCESS, FETCH_FAIL } from "./types";

import ContactService from "../../services/contact.service";

export const getAll = (pageNum, pageSize) => (dispatch) => {
  dispatch({
    type: FETCH_INIT,
  });
  return ContactService.getAll(pageNum, pageSize).then(
    (data) => {
      dispatch({
        type: FETCH_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_FAIL,
        payload: { message },
      });

      dispatch({
        type: "SET_MESSAGE",
        payload: message,
      });

      return Promise.reject();
    }
  );
};
