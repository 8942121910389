/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "./contactuUsDetailModal.css";

class ContactUsModal extends Component {
  render() {
    const { handleClose, heading, show, data } = this.props;
    const showHideClassName = show
      ? "overlay2 modal display-block"
      : "overlay2 modal display-none";
    return (
      <div>
        <div className={showHideClassName}>
          <div className="ModalContainer scrol">
            <div className="ModalDic ">
              <h1 className="headingStyle">{heading}</h1>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <label>
                      <b>Name</b>
                    </label>
                    <br />
                    <p>{data.name}</p>
                  </div>
                  <div className="col-md-6">
                    <label>
                      <b>Email</b>
                    </label>
                    <br />
                    <p>{data.email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>
                      <b>Contact</b>
                    </label>
                    <br />
                    <p>{data.mobileNo}</p>
                  </div>
                  <div className="col-md-6">
                    <label>
                      <b>Date</b>
                    </label>
                    <br />
                    <p>{data.date}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label>
                      <b>Query</b>
                    </label>
                    <br />
                    <p>{data.query}</p>
                  </div>
                </div>
                <div className="row close-contact-btn">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="buttonStyle contact-btn-style"
                      onClick={handleClose}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsModal;
