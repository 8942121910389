import React from "react";
import "./home.css";
import { connect } from "react-redux";
import Layout from "../../commons/layout/layout";

function mapStateToProps(state) {
  return {
    isloading: state.login.isloading,
  };
}

class Home extends React.Component {
  title = "Welcome to Home Page";

  constructor() {
    super();
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div className="Home">
        <Layout>
          <div className="container">
            <div className="d-flex justify-content-center align-self-center">
              <div>
                <div className="heading-container-2">
                  <h2>{this.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Home);
