import axios from "axios";

const baseURL = process.env.REACT_APP_baseURL;

const register = (username, email, password) => {
  return axios.post(`${baseURL}/signup`, {
    username,
    email,
    password,
  });
};

const login = async (email, password) => {
  const data = {
    email,
    password,
    device: "Web",
  };

  return axios.post(`${baseURL}/login`, data);
};

const logout = () => {
  localStorage.removeItem("auth");
};

export default {
  register,
  login,
  logout,
};
