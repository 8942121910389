import React from "react";
import PropTypes from "prop-types";
import Navigation from "../navigation/navigation";

import "./layout.css";

const Layout = ({ children }) => {
  return (
    <>
      <div className="header-nav">
        <h2>Show Case Admin Portal</h2>
      </div>
      <div className="Layout">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-2 collapse show d-md-flex bg-light p-0 min-vh-100 sidebar-style"
              id="sidebar"
            >
              <Navigation />
            </div>

            <div className="col-10 body">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
