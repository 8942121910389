import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute/protectedRoute";

import Dashboard from "./components/dashboard/dashboard";
import Login from "./components/login/login";
import Home from "./pages/home/home";
import AddUser from "./pages/user-add/user-add";
import EditUser from "./pages/user-edit/user-edit";
import ViewUser from "./pages/user-view/user-view";
import ViewSubscriber from "./pages/subscriber-view/subscriber-view";
import ContactList from "./pages/contact-list/contact-list";
import CareerList from "./pages/career-list/career-list";
import AddJob from "./pages/add-job/add-job";

function App() {
  return (
    <div className="App">
      <div className="">
        <Router>
          <Switch>
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/add-user" component={AddUser} />
            <ProtectedRoute
              exact
              path="/change-password"
              component={EditUser}
            />
            <ProtectedRoute exact path="/view-user" component={ViewUser} />
            <ProtectedRoute
              exact
              path="/view-subscriber"
              component={ViewSubscriber}
            />
            <ProtectedRoute
              exact
              path="/view-contact-list"
              component={ContactList}
            />
            <ProtectedRoute
              exact
              path="/view-contact-detail"
              component={ViewSubscriber}
            />
            <ProtectedRoute
              exact
              path="/view-career-list"
              component={CareerList}
            />
            <ProtectedRoute exact path="/add-job" component={AddJob} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
