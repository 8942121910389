/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "./changePassword.css";
import { connect } from "react-redux";

import { update } from "../../store/actions/user";
import store from "../../store/store";
import Alert from "../alert/alert";

function mapStateToProps(state) {
  return {
    message: state.user.message,
  };
}

class changePasswordModal extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      role: "",
      value: "2",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  update = async (data) => {
    const payload = {
      name: this.state.name ? this.state.name : data.name,
      userName: this.state.username ? this.state.username : data.userName,
      password: this.state.password ? this.state.password : undefined,
      role: this.state.role ? this.state.role : data.role,
    };

    if (!payload.name) {
      alert("Please fill the required fields");
      return;
    }

    if (payload.role === "null") {
      alert("Please select the role");
      return;
    }

    // Validation starts
    const nameLimit = 25;
    // const passwordLimit = 15;

    // validation
    if (payload.name.trim().length > nameLimit) {
      alert(
        `Validation Failed, Name field should be less than equals to ${nameLimit} characters`
      );
      return;
    }

    // if (payload.password) {
    //   if (payload.password.trim().length > passwordLimit) {
    //     alert(`Validation Failed, Password field should be less than equals to ${passwordLimit} characters`);
    //     return;
    //   }
    // }

    await store
      .dispatch(update(payload, data.id))
      .then(() => {
        alert(`User updated successfully`);
        window.location.reload();
      })
      .catch(() => {
        alert(`Error Occurred please try again`);
      });
  };

  updateSelect = (e) => {
    this.setState({ value: e.target.value });
  };

  render() {
    const { handleClose, show, data, message } = this.props;

    const showHideClassName = show
      ? "overlay2 modal display-block"
      : "overlay2 modal display-none";
    return (
      <div className="ChangePassword-Modal">
        <div className={showHideClassName}>
          <div className="ModalContainer">
            <div className="ModalDic">
              {message ? <Alert message={message} status="Success" /> : ""}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <h1 className="headingStyle">Edit USER</h1>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="close-btn-style"
                      onClick={handleClose}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <form className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputName4"
                        placeholder="Name"
                        data-validate="Name is required"
                        name="name"
                        defaultValue={data.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Username</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputName4"
                        name="username"
                        placeholder="Username"
                        data-validate="Username is required"
                        defaultValue={data.userName}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputName4"
                        name="email"
                        placeholder="Email"
                        data-validate="Email is required"
                        defaultValue={data.email}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Set Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="inputName4"
                        name="password"
                        placeholder="Password"
                        data-validate="Password is required"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Select Role*</label>
                      <select
                        value={data.role}
                        id="role"
                        name="role"
                        className="form-control"
                        onChange={this.handleInputChange}
                      >
                        <option value="null">Select Role*</option>
                        <option value="Admin">Admin</option>
                        <option value="Incharge">Incharge</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-4">
                <button
                  className="btn button-color"
                  type="button"
                  onClick={() => this.update(data)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(changePasswordModal);
