/* eslint-disable import/prefer-default-export */
import {
  FETCH_INIT,
  FETCH_SUCCESS,
  FETCH_FAIL,
  CREATE_INIT,
  CREATE_SUCCESS,
  CREATE_FAIL,
  UPDATE_INIT,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "./types";

import UserService from "../../services/user.service";

export const getAll = (pageNum, pageSize) => (dispatch) => {
  dispatch({
    type: FETCH_INIT,
  });
  return UserService.getAll(pageNum, pageSize).then(
    (data) => {
      dispatch({
        type: FETCH_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_FAIL,
        payload: { message },
      });

      dispatch({
        type: "SET_MESSAGE",
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const create = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_INIT,
  });
  return UserService.createUser(payload).then(
    (data) => {
      dispatch({
        type: CREATE_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_FAIL,
        payload: { message },
      });

      return Promise.reject();
    }
  );
};

export const update = (payload, id) => (dispatch) => {
  dispatch({
    type: UPDATE_INIT,
  });
  return UserService.updateUser(payload, id).then(
    (data) => {
      dispatch({
        type: UPDATE_SUCCESS,
        // payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_FAIL,
        payload: { message },
      });

      return Promise.reject();
    }
  );
};
