export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGIN_INIT = "LOGIN_INIT";
export const FETCH_INIT = "FETCH_INIT";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAIL = "FETCH_FAIL";
export const CREATE_INIT = "CREATE_INIT";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const CREATE_FAIL = "CREATE_FAIL";
export const UPDATE_INIT = "UPDATE_INIT";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAIL = "UPDATE_FAIL";


export const SUBSCRIBER_FETCH_INIT = "SUBSCRIBER_FETCH_INIT";
export const SUBSCRIBER_FETCH_SUCCESS = "SUBSCRIBER_FETCH_SUCCESS";
export const SUBSCRIBER_FETCH_FAIL = "SUBSCRIBER_FETCH_FAIL";
